// override
.modal-card {
	// overflow-y: auto;
	padding: 5px;
	width: 100%;
	height: 100%;
	margin-top: 55px;
	margin-bottom: 55px;

	@media (min-width: $tablet) {
		width: 750px;
		height: auto;
		margin-top: 80px;
		margin-bottom: 80px;
	}

	&.has-minimal-height {
		height: auto;
	}
 }

.modal.is-active {
	z-index: 100;

	.no-overflow-x {
		overflow-x: hidden;
	}

	.no-overflow-y {
		overflow-y: hidden;
	}
}

footer.modal-card-foot {
	border-top: 1px solid #dbdbdb;
	background-color: #f5f5f5;
}

header.modal-card-head,
footer.modal-card-foot {
	padding: 10px;

	@media (min-width: $tablet) {
		padding: 20px;
	}

	a:not(:last-child) {
		margin-right: 0.5em;
	}

	.button {
		margin-bottom: 0 !important;
	}
}

header.modal-card-head {
	background-color: $mainColor;
	box-shadow: none;

	.modal-card-title {
		color: white !important;
		margin-bottom: 0;
	}
}

button.modal-footer-button {
	border-radius: 10px;
	background-color: $mainColor;
	border: 0;
	color: white;

	&:hover {
		color: white;
		background-color: $focusColor !important;
	}

	&:disabled {
		background-color: $lightBlue !important;
		opacity: 1;
	}
}

div.modal-card-body.top-corners-rounded {
	border-top-left-radius: var(--bulma-modal-card-foot-radius);
	border-top-right-radius: var(--bulma-modal-card-foot-radius);
}

div.modal-card-body p,
div.modal-card-body input,
div.modal-card-body div.enum-select__control {
	margin-bottom: 0;
}

div.modal-card {
	border-radius: 10px;

	label.label {
		margin-bottom: 3px;
	}

	div.control {
		margin-bottom: 20px;
	}

	div.columns:last-child {
		margin-bottom: 0;
	}

	div.inline-inputs > div.column {
		&:not(:first-child) {
			padding-left: 2.5px;
		}

		&:not(:last-child) {
			padding-right: 2.5px;
		}

		@media (min-width: $tablet) {
			padding: 0;

			&:not(:first-child) {
				padding-left: 5px;
			}

			&:not(:last-child) {
				padding-right: 5px;
			}
		}
	}
}

.ad-finder-in-modal {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	.control {
		margin-bottom: 0 !important;
	}
}

.lokimo-modal {
	position: fixed;
	top: 10px;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.6);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;
	border-radius: 8px;

	@media (min-width: $tablet) {
		top : 0;
	}
  }
  

.top-right-divider {
position: absolute;
  top: -29px;
  right: 15px;
  // transform: translateX(-100%);
  background-color: $mainColor;
  color: white;
  border: none;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  padding: 6px 16px;
  font-weight: bold;
  font-size: 1em;
  cursor: pointer;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  z-index: 10;

  @media (min-width: $tablet) {
	top : -30px;
}

  &:hover {
	background-color: $focusColor;
  }
}
