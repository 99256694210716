// Search result
.search-result-header {
	display: flex;
	margin-top: 0;
	margin-bottom: 0;
	width: 100%;
	padding: 10px;
	flex-direction: column;
	justify-content: center;
  
	@media (min-width: $tablet) {
	  flex-direction: row;
	  justify-content: space-between;
	  align-items: center;
	  gap: 20px;
	}

	.outside-query-form-buttons-div {
		padding-left: 10px;
		padding-top: 10px;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		margin-left: auto;

		@media (min-width: $tablet) {
			padding-top: 0;
		}

		.field.is-grouped {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;
			gap: 5px;

			@media (min-width: $desktop) {
				flex-wrap: nowrap;
			}
		}

		.sort-by {
			display: flex;
			min-width: 300px;
			flex-direction: row;
			flex-wrap: nowrap;
			flex-direction: row;
			align-items: center;
			justify-content: center;

			.label {
				padding-right: 10px;
				margin-bottom: 0;
			}
		}

		.outside-submit-buttons {
			color: #404040 !important;
			background-color: $lightBlue !important;
			border-radius: 7px;

			&:hover:not(:disabled) {
				color: black !important;
			}

			&.saved-modified-search-button {
				background-color: $yellowMI !important;
			}

		}

		.icon {
			color: #404040 !important;
		}

	}
}

// search result childs
.search-result-header > div {
	flex: 1;    // Each child has the same lenght
  }

.ads-grid-container {
	gap: 20px;
	padding-top: 30px;
	z-index: 0;
}

.ads-row-container {
	z-index: 0;

	.one-row-property {
		display: flex;


		.left-part-container {
			position: relative;
			overflow: hidden;
			flex: 1;
			border-radius: 10px 10px 10px 0px;
			max-height: 340px;
		}

		.right-part-container {
			position: relative;
			flex: 2;
			max-height: 340px;
			overflow: ellipsis;

			.media-content {
				max-height: 100%;
				display: flex;
			}
		}
	}

}

.button.display-description {
	margin-top: 10px;
}

div.box.property {
	position: relative;
	margin-right: 0;
	margin-left: 0;
	padding: 0;
	margin-bottom: 20px;
	border-radius: 20px;
	box-shadow: 0px 3px 5px #00000029;

	&.single-row {
		margin-top: 30px;
	}

	.button-animation {
		// animation: slideIn 0.3s ease forwards; // issue with the tooltip of the first button displayed before sliding in
		z-index: 10;

		@keyframes slideIn {
			from {
				opacity: 0;
				transform: translateY(-35px);
			}

			to {
				opacity: 1;
				transform: translateX(0);
			}
		}
	}

	.upper-part-container {
		position: relative;
		border-radius: 20px;
		overflow: hidden;
		width: 100%;
		max-width: 500px;
		margin: auto;

		@media (min-width: $tablet) {
			max-width: none;
		}
	}

	.property-right-corner-labels {
		position: absolute;
		border-radius: 5px;
		bottom: 10px;
		right: 10px;
		background: transparent;
		display: flex;
		align-items: center;
		z-index: 2;

		.property-right-corner .tag {
			position: relative;
			bottom: 10px;
			right: 10px;
			color: $mainColor;
			letter-spacing: 0px;
			font-size: 1rem;
			font-weight: 700;
			white-space: nowrap;
			box-shadow: 0px 3px 6px #00000029;
			cursor: default;

			@media (min-width: $tablet) {
				font-size: 1rem;
			}
		}
	}

	.property-left-corner-labels {
		position: absolute;
		border-radius: 5px;
		bottom: 10px;
		left: 10px;
		background: transparent;
		display: flex;
		flex-direction: column;
		align-items: center;
		z-index: 2;
	}

	article.media {
		margin-bottom: 0;

		@media (min-width: $tablet) {
			min-height: 250px;
		}
	}

	div.media-left {
		margin: auto;
		margin-top: 0;
		padding-top: 20px;

		@media (min-width: $tablet) {
			padding-top: 0;
		}

		img {
			display: block;
			height: 210px;
			width: 270px;
			cursor: pointer;

			@media (min-width: $tablet) {
				border-radius: 5px 0 0 0;
			}
		}
	}

	div.media-content {
		padding: 10px;

		@media (min-width: $tablet) {
			padding: 8px 8px 0px 8px
		}
	}

	footer {
		margin-bottom: 0;
	}
}

div.property-veil {
	background-color: black;
	width: 100%;
	height: 100%;
	position: absolute;
	opacity: 0.3;
	pointer-events: none;
	display: none;
	border-radius: 20px;
	z-index: 2;

	&.active {
		display: block;
	}
}

a.property-top-title {
	margin-right: 5px;
	font-size: 18px;
	font-weight: 800;
	text-transform: uppercase;
	line-height: 1.2em;

	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 1;
	line-clamp: 1;
	text-overflow: ellipsis;
	overflow: hidden;
}

button.property-corner-button {
	border-radius: 9999px !important;
	width: 32px;
	height: 32px;
	position: absolute;
	border: 0;
	z-index: 3;

	&.is-colored {
		&:disabled {
			background-color: $darkGrey;
		}
	}

	&.is-fav {
		&:focus {
			color: white;
			box-shadow: none;
		}

		&:active {
			color: $redMI;
			border: 0;
		}

		&.clicked {
			color: $redMI !important;
		}
	}

	&.is-yellow {
		&:focus {
			color: white;
			box-shadow: none;
		}

		&:active {
			background-image: none;
			background-color: $yellowMI;
			border: 0;
		}

		&.clicked {
			background-image: none;
			//background-color: green !important;
			background-color: $yellowMI !important;

			&:hover {
				//background-color: forestgreen !important;
				background-color: #d8ac1a !important;
			}
		}
	}
}

span.property-creation-date-tag {
	display: inline-block;
	padding: 0;
	color: $mainColor;
	font-weight: 500;
	font-size: 11px;
}

div.property-attribute {
	text-align: center;
	font-weight: 600;

	&.property-location-attribute {
		white-space: nowrap;

		.city:not(.is-extended) {
			display: inline-block;
			max-width: 105px;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	&.property-origins-attribute {
		flex-wrap: wrap;
		border-right: 0 !important;
		min-width: 50%;

		@media (min-width: $desktop) {
			min-width: auto;
		}

		button.origin-logo {
			height: 80px;
		}

		a {
			border-radius: 10px;
			overflow: hidden;
		}
	}
}

span.property-big-value {
	font-size: 15px;
}

span.property-stats {
	font-size: 12px;
	font-weight: 500;
	display: block;

	&.is-danger {
		color: $redMI !important;
		cursor: default;
	}
}

img.origin-logo,
button.origin-logo {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 49px;
	width: 50px;
}

div.property-duplicate-attributes {
	display: flex;
	flex-direction: column;

	span.property-duplicate-attribute {
		border-radius: 0;
		width: 50px;
		font-size: 0.6rem;

		&:last-child {
			margin-top: -1px;
		}
	}
}

.property-display-description {
	text-align: center;

	.display-description {
		cursor: pointer;
		font-size: 0.8em;
		border-radius: 10px;
		font-weight: 600;
		margin-bottom: 10px;
		background-color: $lightBlue;
		color: #404040;
		border: none;
	}
}

p.property-hidden {
	line-height: 2;
	padding: 10px;

	button {
		cursor: pointer;
		font-size: 0.8em;
		margin-left: 5px;
	}
}

.property-main-icon {
	padding-bottom: 5px;
	color: $mainColor;
	height: 1.5em;
}

.property-description {
	display: flex;
	padding-top: 5px;
	padding-bottom: 10px;
	flex-grow: 1;
	align-items: center;
	justify-content: center;

	>div {
		overflow: hidden;
		text-overflow: ellipsis;
		//width: 95%;
	}
}

.additional-info {
	display: flex;
	max-width: 0;
	transition: max-width 1s ease-in-out, transform 1s ease-in-out;
	transform: translateX(-100%);
	will-change: max-width, transform;
	margin-left: 10px;

	&.show {
		max-width: 500px;
		transform: translateX(0);
	}
}

.expand-icon {
	transition: transform 0.3s ease;
	color: white;
	background: $mainColor;
	width: 10px;
	height: 10px;
	padding: 5px;
	border-radius: 50%;
	cursor: pointer;
	margin-top: 10px;
}

.expand-icon.expanded {
	transform: rotate(180deg);
}

.card-footer-items {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	min-height: 140px;
	// overflow: hidden;
	box-sizing: border-box;

	.card-footer-item {
		display: flex;
		justify-content: center;
		align-items: center;
		flex: 1 1 auto;
		border-top: 1px solid #00000029;
		border-right: 1px solid #00000029;
		padding: 10px 0 10px 0;
		line-height: 1.3;
		margin-right: -1px;
		padding-right: 3px;
		padding-left: 3px;
		font-size: 0.9em;

		.has-text-danger,
		.has-text-success {
			font-size: 1em;
		}

		strike {
			color: $darkGrey;
		}

		>span.tag {
			margin: 3px;

			svg {
				margin-right: 3px;
			}
		}
	}
}

.location-and-stats-div,
.simulation-and-history-div {
	display: flex;
	flex-wrap: nowrap;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

button.property-display-simulation-form {
	height: 25px;
	margin-top: 5px;

	&:first-child {
		margin-right: 2.5px;
	}

	&:last-child {
		margin-left: 2.5px;
	}
}

div.location-stats,
div.property-simulation-form {
	background-color: #F2F2F2;
	border-top: 1px solid #00000029;
	margin-bottom: 0 !important;
	border-radius: 0px 0px 20px 20px;
}

div.location-stats {
	width: 100%;

	.label {
		background-color: $mainColor;
		color: white;
		border-radius: 5px;
		font-size: 14px;
	}

	.section {
		padding: 10px;
		font-size: 13px;
	}

	a {
		font-size: 10px;
		color: $mainColor;
	}
}

div.property-simulation-form {
	div.property-simulation-field {
		display: block;
		padding: 5px;

		@media (min-width: $tablet) {
			display: inline-block;
		}

		label {
			display: inline-block;
			font-size: 12px;
			padding-bottom: 0;
			margin-bottom: 3px;
		}
	}

	div.property-simulation-result>span {
		font-size: 13px;
		margin-bottom: 10px !important
	}
}

.history-text h4 {
	font-size: 1em;
	color: $mainColor
}

div.property-tags {
	display: flex;
	justify-content: center;
	margin-top: 5px;
	padding-right: 5px;

	@media (min-width: $tablet) {
		justify-content: left;
	}
}

textarea.property-user-comment {
	resize: none;
	margin-top: 0;
	max-height: 100px;
	padding: 5px;
}

div.property-user-comment {
	cursor: pointer;
	padding: 5px;
	margin: 0 0 10px 0 !important;
	max-height: 100px;
	overflow-y: auto;
}

div.property-user-comment-buttons {
	margin-bottom: 0 !important;

	button {
		text-align: center;
		margin: 10px 5px 10px 5px;
	}
}

.score-wrap {
	&:not(.single-row) {
		position: absolute;
		top: 0px;
		right: 0px;
		transform: scale(0.5);
		transform-origin: right top;
	}
}

.score {
	width: 200px;
	height: 200px;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
}

.score-value {
	position: absolute;
	z-index: 2;
	text-align: center;
}

.score-number {
	font-size: 2.5rem;
	font-weight: 600;
	margin-bottom: 15px;
}

div.property-left-corner-icons {
	position: absolute;
	height: 32px;
	top: -16px;
	left: 10px;
	z-index: 3;
}

span.property-index {
	position: absolute;
	bottom: 0;
	left: 10px;
	bottom: 4px;
	font-size: 12px;
}

.media-content {
	max-width: 100%; //forbid x-scroll.
}

// saved ads panel
div.saved-property-container {
	border-bottom: 1px solid #dbdbdb;
	position: relative;

	&.kanban {
		border-bottom: none;
	}

	.property-veil {
		border-radius: 0;
	}

	.saved-property {
		display: flex;
		background-color: white;
		border-radius: $criterionBorderRadius;
		max-height: 150px;

		&.kanban {
			height: 110px;
		}

		.saved-property-picture {
			flex: 1;
			align-items: center;
			justify-content: center;
			position: relative;
			padding: 10px 0px 10px 10px;
			cursor: pointer;

			&.kanban {
				padding: 5px 0px 5px 5px;
			}

			img {
				border-radius: 5px;
				height: 100%;
				aspect-ratio: 1.8 / 1;
				object-fit: cover;
			}

			.saved-ad-actions-buttons {
				position: absolute;
				bottom: 10px;
				left: 50%;
				transform: translateX(-50%);
				display: flex;
				gap: 10px;

				@media (min-width: $tablet) {
					display: none; // Hidden by default
				}
				
				.icon-button {
					background: $mainColor;
					color: white;
					border: none;
					border-radius: 50%;
					width: 30px;
					height: 30px;
					display: flex;
					justify-content: center;
					align-items: center;
					cursor: pointer;

					&:hover {
						background: $focusColor;
					}
				}
			}
		}

		// Show buttons only when hover on the global container
		&:hover .saved-ad-actions-buttons {
			display: flex;
		}

		.saved-property-description {
			flex: 1;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: center;
			padding: 10px;

			.description-link {
				color: $mainColor;
				font-size: 0.9em;
				font-weight: 700;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 5;
				line-clamp: 5;
				overflow: hidden;
				text-overflow: ellipsis;

				&.kanban {
					font-size: 0.8em;
				}

				.saved-property-title {
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 3;
					line-clamp: 3;
					overflow: hidden;
					text-overflow: ellipsis;
				}

				.saved-property-price,
				.saved-property-location {
					color: #888888;
					font-weight: 600;
				}
			}
		}
	}
}


.mini-div-category-selector {
	margin-left: 10px;
	margin-top: 2px;
}

.lokimo {
	position: relative;
	overflow: hidden;
	color: white;
	font-weight: 600;
	padding: 0.5em 1em;
	border: none;
	border-radius: 8px;
	cursor: pointer;
	font-size: 1rem;
	background: linear-gradient(90deg, #1e30f3 0%, #ff69c7 100%);
	z-index: 0;
  
	// Transition
	&::before {
	  content: '';
	  position: absolute;
	  top: 0; left: 0; right: 0; bottom: 0;
	  background: linear-gradient(90deg, #ff69c7 0%, #1e30f3 100%);
	  opacity: 0;
	  transition: opacity 0.5s ease;
	  z-index: -1;
	}
  
	&:hover::before {
	  opacity: 1;
	}
  
	&:hover {
	  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
	}
  
	&:active {
	  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
	}
  
	&:disabled {
	  opacity: 0.6;
	  cursor: not-allowed;
	}
  }
  
  
  