@charset 'utf-8';

// font
$body-family: 'Montserrat', sans-serif;

// colors
$mainColor: #5482DB;
$focusColor: #4065ad;
$gradientColor: #186ee6;
$borderColor: #dbdbdb;
$noInteractionColor: #E5E5E5;
$redMI: #eb6156;
$orangeMI: #eb9956;
$focusRed: #df4336;
$darkGrey: #a9a9a9c9;
$lightBlue: #c8d7f5;
$veryLightBlue: #F0F5FF;
$yellowMI: #F2C42B;
$placeholderColor: #808080;
$greenMI: #48c78e;
$focusGreenMI: #3b9f72;
$backgroundColor: #f6f8fd;

// border radius
$borderRadius: 20px;
$criterionBorderRadius: 10px;

// Bulma
@import '../../node_modules/bulma/bulma.scss';
@import '../../node_modules/bulma/sass/utilities/initial-variables.scss'; // because @use is used in bulma.scss
@import '../../node_modules/bulma-tooltip/dist/css/bulma-tooltip.min.css'; // https://wikiki.github.io/elements/tooltip/
@import '../../node_modules/bulma-social/css/single/facebook/facebook.min.css'; // https://github.com/aldi/bulma-social
@import '../../node_modules/bulma-social/css/single/linkedin/linkedin.min.css'; // https://github.com/aldi/bulma-social
@import '../../node_modules/bulma-social/css/single/instagram/instagram.min.css'; // https://github.com/aldi/bulma-social

// internal dependencies
@import './account_panels.scss';
@import './ad.scss';
@import './fields.scss';
@import './footer.scss';
@import './kanban.scss';
@import './map.scss';
@import './markdown_page.scss';
@import './modal.scss';
@import './montserrat.scss';
@import './navbar.scss';
@import './notification.scss';
@import './pricing_table.scss';
@import './query_form.scss';
@import './quotas_bar.scss';
@import './roadmap.scss';
@import './spinner.scss';
@import './simple_image_carousel.scss';
@import './toggle_switch.scss';
@import './tooltips.scss';

html,
body {
	overflow-x: hidden;
	font-family: $body-family;
	font-size: 14px;
	font-weight: 300;
	background-color: $backgroundColor;

	@media (min-width: $tablet) {
		font-size: 15px;
	}
}

body:after {
	content: '';
	display: block;
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100vh;
	/* to prevent background image from jumping on Iphones */
	z-index: -10;
	background-color: $backgroundColor;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-size: cover;
	background-attachment: scroll;
	transform: translate3d(0, 0, 0);
	/* to prevent white area when scrolling on Iphones */
}

#app {
	/* https://philipwalton.github.io/solved-by-flexbox/demos/sticky-footer/ */
	display: flex;
	min-height: calc(100vh - 50px - var(--nav-height));
	flex-direction: column;

	@media (min-width: $tablet) {
		min-height: 100vh;
	}
}

#root {
	flex: 1;
	margin-top: 0px;
	margin-bottom: 50px;

	@media (min-width: $tablet) {
		margin-top: 62px;
		margin-bottom: 0px;
	}
}

// https://stackoverflow.com/questions/3516173/ipad-safari-how-to-disable-the-quick-blinking-effect-when-a-link-has-been-hit
a {
	-webkit-tap-highlight-color: transparent;
}

.label {
	font-weight: 600;
}

.field.has-addons .control:last-child:not(:only-child) .button {
	border-bottom-right-radius: $borderRadius;
	border-top-right-radius: $borderRadius;
}

.sharing-fields {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	max-width: 100%;
	align-items: center;
	justify-content: center;
	gap: 10px;

	.share-button {
		display: flex;
		font-size: 12px;
	}
}

.is-very-light-blue {
	padding: 20px;
	background-color: $veryLightBlue;
}

h4 {
	font-weight: 700;
	padding: 0 10px;
}

.white-h4 {
	color: white;
	margin-bottom: 5px;
	// white-space: nowrap;
}

h5.white-h5 {
	color: white;
	margin-bottom: 2px;
	font-style: italic;
	font-size: 0.8rem;
	font-weight: 700;
	text-align: center;
}

article.message {
	width: 80%;
	padding: 10px;
	border-radius: 10px;
	margin: auto;

	&.is-info>div.message-header {
		background-color: $mainColor;
		color: white;
	}

	div.message-body {
		text-align: center;
		padding: 5px;

		@media (min-width: $tablet) {
			padding: 10px
		}
	}
}

article.properties-selector {
	position: fixed;
	left: 0;
	border-radius: 10px;
	z-index: 101;
	pointer-events: auto;
	bottom: 50px;
	width: 100%;

	@media (min-width: $tablet) {
		bottom: 0;
		position: fixed;
		left: 50%;
		transform: translateX(-50%);
		bottom: 10px;
		width: 600px; // to cover the toolbox below
	}

	div.properties-selector-header {
		width: 100%;
		border-radius: 10px 10px 0px 0px;
		background-color: $mainColor;
		padding: 10px;
		color: white;
		text-align: center;
		font-size: 14px;

		@media (min-width: $tablet) {
			font-size: 16px;
		}
	}

	div.properties-selector-body {
		width: 100%;
		border-radius: 0px 0px 10px 10px;
		background-color: $noInteractionColor;
		padding: 10px;
		text-align: center;
		font-size: 12px;

		@media (min-width: $tablet) {
			font-size: 14px;
		}
	}
}

div.is-vertical-centered-mobile {
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: calc(100vh - 50px);

	@media (min-width: $tablet) {
		min-height: auto;
	}

	>.column {
		height: 100%;
	}
}

.black-text-shadow {
	text-shadow: 0 5px 8px black
}

.vertical-direction-on-mobile {
	flex-direction: column;

	@media (min-width: $tablet) {
		flex-direction: row;
	}
}

.control.disabled:hover {
	cursor: not-allowed;
}

// react-select inputs
.input {
	min-height: 38px; // to be equal to react-select inputs
	flex-grow: 1;

	&:read-only {
		cursor: default;
	}
}

.excluded-locations-input {
	.enum-select__multi-value {
		background: $redMI;
	}
}

div.enum-select__control {
	height: 100%;
	border-radius: $borderRadius;
	cursor: pointer;

	&--is-focused {
		border-color: none;
		box-shadow: none;
	}
}


div.enum-select__placeholder,
div.enum-select__single-value {
	font-size: 0.9rem;
}

div.enum-select__placeholder {
	color: $placeholderColor;
}

div.enum-select__option--is-focused,
.enum-select__option--is-selected {
	background-color: $mainColor;
	color: white;
}

div.enum-select__menu {
	z-index: 160; // be able to be over anything, even navbar
	border-radius: $borderRadius;
	overflow: hidden;
	font-size: 0.9rem;
}

div.enum-select__option--is-selected {
	background-color: white;
	color: $mainColor;
	font-weight: 600;
}

div.alert-selector {
	width: 100%;

	@media (min-width: $tablet) {
		flex-grow: 1;
		width: auto;
	}

}

section.commercial-argument-section {
	column-gap: 40px;
	justify-content: center;
	align-items: center;
	padding: 0;
	margin: 0;

	&.with-vertical-padding {
		@media (min-width: $tablet) {
			padding-top: 20px;
			padding-bottom: 20px;
		}
	}

	&.with-filled-background {
		background-color: $mainColor;
	}

	div.inline-arguments {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		justify-content: space-between;

		@media (min-width: $tablet) {
			padding: 50px;
		}

		div.inline-argument {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			text-align: center;
			width: 33%;

			img {
				max-width: 75%;

				@media (min-width: $tablet) {
					max-width: none;
				}
			}
		}
	}

	h2.argument-title {
		font-size: 1.4em;
		font-weight: 800;
		padding: 5px;
		color: black;

		&.white {
			color: white !important;
		}
	}

	p.argument-text {
		padding: 5px;
		font-weight: 400;
		margin: 5px;
		font-size: 0.9em;

		@media (min-width: $tablet) {
			margin: 0px;
			font-size: 1em;
		}
	}

	.call-to-action {
		margin-top: 20px;
		font-weight: 600;
	}

	.picture {
		max-height: 340px;
		border-radius: 20px;
		overflow: hidden;
		margin: 10px;
		padding: 0;
		display: none;

		@media (min-width: $tablet) {
			display: block;
		}
	}

	img {
		display: flex;
		border-radius: 10px;
		justify-content: center;
		align-items: center;
	}
}

h1,
h2,
h3 {
	&.is-colored {
		color: $mainColor;
	}
}

// Hide the indicator separator
.enum-select__indicator-separator {
	display: none;
}

//Custom selected element in list
div.enum-select__multi-value {
	border-radius: $criterionBorderRadius;
	background-color: $noInteractionColor;
	overflow: hidden;

	// Delete cross
	.enum-select__multi-value__remove {
		&:hover {
			background-color: $redMI;
			color: white;
		}
	}
}

.alert-input {
	justify-content: center;
	font-size: 1em;
}

.header-search {
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: transparent;
	border-radius: $borderRadius;
	margin-top: 40px;

	@media (min-width: $tablet) {
		margin-top: 0px;
	}

	.search-type {
		display: flex;
		background-color: white;
		border-radius: 0 0 $borderRadius $borderRadius;
		align-items: center;
		justify-content: center;
		box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.3);
		flex-direction: column;
		padding: 8px;
		width: 100%;

		.type-and-alert {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			width: 100%;
		}

		@media (min-width: $tablet) {
			min-width: 630px;
			width: 50%;
			border-radius: $borderRadius;
			margin-top: 20px;

			.type-and-alert {
				flex-direction: row;
				justify-content: space-between;
			}
		}

		.alert-selector-group {
			display: flex;
			justify-content: flex-end;
			align-items: flex-end;
			flex-direction: row;
			width: 100%;
			padding: 8px;

			@media (min-width: $tablet) {
				max-width: 50%;
				padding: 0;

			}
		}
	}
}

div.property-count-container {
	display: flex;
	flex-direction: row;
	align-items: stretch;
	font-weight: 600;
	color: #1f1f1f;
	font-size: 1em;
	margin-top: 20px;
	margin-bottom: 20px;

	@media (min-width: $tablet) {
		font-size: 1.1em;
	}
}

.property-count-value {
	display: flex;
	align-items: center;
	background-color: $lightBlue;
	border-radius: 10px;
	padding-left: 10px;
	padding-right: 10px;
	height: 100%;
	margin-right: 5px;
}

.property-count-text {
	display: flex;
	flex-direction: column;
	justify-content: center;

	.additional-info-text {
		font-size: 0.8em;
		color: #4a4a4a;
	}
}

a,
.is-colored {
	color: $mainColor;
	border-radius: 8px;
}

a:not(.button) {
	&:hover {
		color: $focusColor;
		transition: none;
	}
}

.button.is-small {
	border-radius: 8px !important;

	&.is-rounded {
		border-radius: $borderRadius !important;
	}
}

.button.is-rounded {
	border-radius: 30px;
}

.is-full-width {
	width: 100%;
}

.button.is-social-button {
	height: 25px;
	width: 20px;
	margin-left: 5px;
	margin-right: 5px;
	border: 0;

	@media (min-width: $tablet) {
		height: 30px;
		width: 30px;
	}
}

.footer-badge {
	height: 25px;
	width: 80px;
	margin-left: 5px;
	margin-right: 5px;
	border-radius: 4px;

	@media (min-width: $tablet) {
		height: 30px;
		width: 100px;
	}
}

.tag.is-none {
	border-radius: 8px;
	background-color: $darkGrey;
	color: white;
}

.button.is-danger {
	background-color: $redMI !important;
	border-radius: 8px;
	color: white;

	&:hover:not(:disabled) {
		background-color: $focusRed !important;
	}

	&:disabled {
		cursor: not-allowed;
	}
}

.button.is-colored:not(.is-danger),
button.delete.is-colored:not(.is-danger) {
	background-color: $mainColor;
	color: white;
	border: none;

	&:not(.is-large) {
		font-size: 14px;
	}

	&:focus,
	&:active {
		color: white;
		background-color: $mainColor;
		border: none;
		box-shadow: none !important;
	}

	&:hover:not(:disabled) {
		background-image: none;
		background-color: $focusColor;
	}

	// must be last
	&:hover:not(:disabled) {
		color: white;
		background-color: $focusColor;
		border: none;
	}

	&:disabled {
		cursor: not-allowed;
	}

	&.is-whatsapp {
		background-color: #25D366;
		background-image: none;

		&:hover:not(:disabled) {
			background-color: #075E54;
		}
	}

	&.is-telegram {
		background-color: #2AABEE;
		background-image: none;

		&:hover:not(:disabled) {
			background-color: #0088CC;
		}
	}

	&.is-reversed {
		background-color: white;
		color: $mainColor;
	}

	&.is-green {
		background-color: $greenMI;

		&:hover:not(:disabled) {
			background-color: $focusGreenMI;
		}
	}

	&.is-focus-color {
		background-color: $focusColor;

		&:hover:not(:disabled) {
			background-color: $gradientColor;
		}
	}

	&.shadow {
		box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
	}
}

.button.is-right-label-button {
	border-radius: 4px 4px 0 0;
	margin-right: 2px;
	bottom: -1px;
	height: 22px;
	padding: 0 5px 0 5px;

	@media (min-width: $tablet) {
		margin-right: 0;
	}
}

.has-text-danger {
	color: $redMI !important;
}

.tag.is-colored {
	color: white;
	background-color: $mainColor;
	border-radius: 10px;
}

.tag.is-reversed-colored {
	color: $mainColor;
	background-color: white;
	border-radius: 10px;
}

.tag.multiline {
	height: auto;
	display: flex;
	flex-wrap: wrap;
}

.tag.is-info {
	color: white;
	background-color: $mainColor !important;
}

.tag.informative {
	border-radius: 8px !important;
	font-size: 0.8em;

	&.success {
		background-color: $noInteractionColor;
	}

	&.danger {
		background-color: $redMI;
		color: white;
	}
}

.tag.is-fully-satisfied {
	color: white;
	background-color: $focusColor;
	border-radius: 10px;
}

.tag.is-partially-satisfied {
	color: white;
	background-color: $mainColor;
	border-radius: 10px;
}

.tag.is-poorly-satisfied {
	color: white;
	background-color: $orangeMI;
	border-radius: 10px;
}

.tag.not-satisfied {
	color: white;
	background-color: $redMI;
	border-radius: 10px;
}

.tag.is-danger {
	background-color: $redMI;
	color: white;
}

.tag.is-warning {
	background-color: $yellowMI !important;
	color: black;
}

.tag.is-1em {
	font-size: 1em;
}

span.tooltip-icon {
	margin-left: 3px;
	cursor: pointer;

	h5.tooltip-icon-side-text {
		margin: 0;
		display: inline;
	}

	&:not(.icon-without-hover) {
		svg:hover {
			color: $focusColor;
		}
	}

	@media (min-width: $tablet) {
		&::before {
			font-size: 14px !important;
		}
	}
}

span.clickable {
	cursor: pointer;

	&.is-colored {
		&:hover {
			color: $focusColor;
		}
	}
}

// harmonize placeholder color
input::placeholder,
textarea::placeholder,
select::placeholder {
	color: $placeholderColor;
}

// To be sure it works on all brower
input::-moz-placeholder,
textarea::-moz-placeholder,
select::-moz-placeholder {
	color: $placeholderColor;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder,
select:-ms-input-placeholder {
	color: $placeholderColor;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder,
select::-webkit-input-placeholder {
	color: $placeholderColor;
}

// remove arrows in number inputs
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type="submit"] {
	font-weight: 700;
}

/* Firefox */
input[type='number'] {
	-moz-appearance: textfield;
	appearance: textfield;
	width: 100%;
	min-width: 0;
}

input[type='text'][disabled]:hover,
input[type='checkbox'][disabled]:hover,
input[type='radio'][disabled]:hover {
	cursor: not-allowed;
}

input[type='text'][disabled] {
	opacity: 0.6;
	background-color: lightgray;
}

// remove focus outline
:focus {
	outline: none;
}

::-moz-focus-inner {
	border: 0;
}

// override
.columns {
	margin: 0;
}

// override
pre {
	background-color: transparent;
}

// override
.box {
	margin-top: 15px;
	padding: 15px;
	border-radius: 15px;
}

// override
.control.has-icons-left .icon,
.control.has-icons-right .icon {
	z-index: 0;
}

// override
.tags:not(:last-child) {
	margin-bottom: 0;
}

// override
.tabs:not(:last-child) {
	margin-bottom: 0;
}

// inputs, buttons and labels
div.control {
	margin-bottom: 0;

	.button {
		height: 100%;
		font-weight: 700;
	}
}

.input::placeholder {
	opacity: 1;
	font-size: 12px;

	@media (min-width: $tablet) {
		font-size: 14px;
	}
}

.input {
	border-radius: $borderRadius;
}

.control,
.input,
label {

	// to fix a checkbox bug
	:invalid {
		box-shadow: none;
	}
}

// checkboxes
label.checkbox {
	&:hover:not([disabled]) {
		color: $focusColor;
	}

	&[disabled] {
		cursor: not-allowed;
	}
}

div.control.has-icons-right>span.icon {
	color: white !important;
	background-color: $mainColor;
	border-radius: 0 10px 10px 0;
	height: 100%;

	&:hover,
	&:active,
	&:focus {
		color: white !important;
	}
}

// inputs in search form
div.query-form {

	label.label,
	span.icon {
		font-size: 0.85rem;
		letter-spacing: -0.5px;
	}
}

div.action-button {
	margin: 0;
	padding: 0;
	height: 50px;

	&.is-small {
		height: 30px;
	}

	input,
	a {
		height: 50px;
		font-size: 14px;
		padding-left: 35px;
		padding-top: 5px;
		padding-bottom: 8px;
		line-height: 0px;

		@media (min-width: $tablet) {
			font-size: 16px;
		}

		&.is-small {
			border-radius: 99px;
			height: 30px;
			padding-left: 30px;
			padding-bottom: 6px;
			font-size: 10px;

			@media (min-width: $tablet) {
				font-size: 12px;
			}
		}
	}

	span.icon {
		height: 100% !important;
		color: white !important;
	}
}

button.hide-text {
	background-color: transparent;
	box-shadow: none;
	border: 0;
	vertical-align: sub;
	padding-top: 0;
	padding-bottom: 0;
	padding-left: 10px;
	max-height: 20px;

	svg {
		font-size: 15px;
	}

	&:focus {
		box-shadow: none !important;
	}
}

.button {
	font-weight: 400;
}

textarea.textarea::placeholder {
	color: $darkGrey;
}

// social network buttons
button.auth-button {
	color: white;
	font-size: 14px;
	font-weight: 700;
	font-family: inherit;
	border-radius: 5px;
	border: 0;
	display: inline-flex;
	align-items: center;
	padding: 10px;
	height: 40px;

	>svg {
		margin-right: 5px;
	}

	&:hover:not(:disabled) {
		cursor: pointer;
	}

	&:disabled {
		opacity: 0.5;

		&:hover {
			cursor: not-allowed;
		}
	}
}

button.apple-auth-button {
	background-color: black;
	margin-left: 5px;
}

button.google-auth-button {
	background-color: white;
	margin-right: 5px;
	padding: 0;

	>img {
		height: 50px;
	}
}

button.facebook-auth-button {
	background-color: #4c69ba;
	margin-left: 5px;
	margin-right: 5px;
}

// checkout buttons
button.checkout-button {
	border: 0;
	padding: 0;
	width: 200px;
	height: 35px;

	&:disabled {
		opacity: 0.5;

		>* {
			pointer-events: none;
		}

		&:hover {
			cursor: not-allowed;
		}
	}
}

button.paypal-checkout-button {
	z-index: 0;
	position: relative;
}

button.stripe-checkout-button {
	font-size: 16px;
	font-weight: 700;
	padding: 5px 10px 5px 10px;
	color: white;
	background-color: #5469d4;
	border-radius: 4px;

	&:hover:not(:disabled) {
		background-color: #4a5cb6;
		cursor: pointer;
	}
}

a.hero-subtitle-link {
	color: white;
	text-decoration: underline;
	text-underline-offset: 5px;

	&:hover {
		text-shadow: 0px 5px 40px white;
	}
}

div.columns-wrap {
	padding: 0;
	margin: 0 !important;
}

div.box-with-padding {
	padding: 15px;

	@media (min-width: $tablet) {
		padding: 30px;
	}
}

div.alert-box {
	margin-top: 10px;
	margin-bottom: 10px;
	max-height: 100%;
}

h2.title {
	padding-top: 10px;
	padding-bottom: 20px;

	@media (min-width: $tablet) {
		padding-top: 0;
		padding-bottom: 30px;
	}
}

h3.subtitle {
	padding-top: 0;
	padding-bottom: 0;
	font-size: 14px;

	@media (min-width: $tablet) {
		font-size: 20px;
	}
}

// forms
form.alert-form {
	div.control {
		margin-bottom: 20px;
	}

	label.label {
		margin-bottom: 3px;
	}

	div.inline-inputs {
		margin-bottom: 0;
	}

	div.inline-inputs>div.column {
		&:not(:first-child) {
			padding-left: 2.5px;
		}

		&:not(:last-child) {
			padding-right: 2.5px;
		}

		@media (min-width: $tablet) {
			padding: 0;

			&:not(:first-child) {
				padding-left: 5px;
			}

			&:not(:last-child) {
				padding-right: 5px;
			}
		}
	}
}

table.table {

	tr>th,
	tr>td {
		padding-left: 3px;
		padding-right: 3px;

		&.abbr::after,
		abbr::after {
			content: attr(data-full);
		}
	}

	.has-text-centered.light {
		font-size: 0.8em;
		max-width: 150px;
	}

	.has-text-centered.fix-options {
		min-width: 75px;
	}

	span.tag {
		margin-top: 5px;
		color: white;
		border-radius: 7px;
	}
}

ul.pros-cons-list {
	margin-top: 10px;
	margin-bottom: 0;
	margin-left: 0;
	text-align: center;
	list-style: none;

	svg {
		margin-right: 5px;
	}
}

li.empty-line {
	display: none;

	@media (min-width: $tablet) {
		display: block;
		visibility: hidden;
	}
}

div.pricing-box {
	line-height: 2;
	padding-top: 20px;
	padding-bottom: 20px;
	border: 1px solid $mainColor;
	margin-top: 50px;
	margin-bottom: 50px;
	background-color: aliceblue;
	color: $mainColor;

	h4 {
		color: $mainColor;
	}

	a.button {
		margin-top: 20px;
	}
}

div.feature-thumbnail {
	text-align: center;
	margin-bottom: 50px !important;

	img {
		cursor: pointer;
		object-fit: cover;
		box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
		height: 200px;
		width: 300px;
	}
}

div.pwa-thumbnail {
	margin-bottom: 30px !important;

	img {
		cursor: pointer;
		object-fit: cover;
		box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
	}
}

// tag displaying the number of ads
span.tag {
	&.is-loading {
		position: relative;
		pointer-events: none;
		padding: 20px;
		color: white;

		&:after {
			// @include loader;
			position: absolute;
			top: calc(50% - 10px);
			left: calc(50% - 10px);
			width: 20px;
			height: 20px;
			border-width: 2px;
		}
	}
}

div.news-screenshot {
	text-align: center;
	max-width: 1000px;
	margin: auto;

	&:has(+ h5) {
		// screenshot followed by a subtitle
		margin-bottom: 20px;
	}

	img {
		box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
	}
}

h5.news-subtitle {
	margin-bottom: 5px;
}

div.info-link {
	margin-bottom: 10px;
	text-align: center;
	font-weight: 600;
}

.has-adaptative-font-size {
	font-size: 14px !important;

	@media (min-width: $tablet) {
		font-size: 16px !important;
	}

	@media (min-width: $desktop) {
		font-size: 18px !important;
	}
}

span.option-remove-icon {
	margin-left: 5px;

	&:hover {
		color: $focusColor;
	}
}

// warning message displayed in the subscription modal
p.warning-message {
	color: $redMI;
	cursor: default;
	text-align: center;
}

div.tab-description {
	text-align: center;
	margin-bottom: 10px;

	@media (min-width: $tablet) {
		margin-bottom: 20px;
		font-size: 0.9rem;
	}

	div.notification {
		display: inline-block;
	}
}

div.double-box-container {
	display: flex;
	max-width: 1400px;
	margin: auto;
	flex-wrap: wrap;

	>* {
		flex-basis: 100%;

		@media (min-width: $desktop) {
			flex-basis: 50%;
		}
	}
}

.column-on-mobile {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	@media (min-width: $tablet) {
		flex-direction: row;
	}

}

@media (min-width: $tablet) {
	.column {
		padding-top: 7px;
		padding-bottom: 7px;
	}

	div.column.is-one-tenth-tablet {
		flex: none;
		width: 10%;
		padding-left: 8px;
		padding-right: 8px;
	}

	div.column.is-one-sixth-tablet {
		flex: none;
		width: 16.666666667%;
		padding-left: 8px;
		padding-right: 8px;
	}

	div.pricing-box {
		margin: 0;
	}

	table.table {

		tr>th,
		tr>td {
			padding-left: 10px;
			padding-right: 10px;
		}
	}

	span.creation-date-tag {
		font-size: 13px;
	}

	button.hide-text {
		padding-left: 15px;

		svg {
			font-size: 25px;
		}
	}

	// spread the ad options on several lines
	div.tags.is-half-mobile {
		max-width: 15%;
	}

	div.tools-tabs {
		font-size: 0.9rem;
	}
}

@media (min-width: $desktop) {

	// keep a minimum width for all the pages to prevent hard squeezing
	div.column.is-5-desktop {
		min-width: 700px;
	}

	div.pwa-thumbnail {
		padding-left: 30px;
		padding-right: 30px;
	}

	button.hide-text {
		svg {
			font-size: 35px;
		}
	}

	div.tools-tabs {
		font-size: 1.2rem;
	}
}

@media (min-width: $desktop) {
	div.column.is-one-tenth-desktop {
		flex: none;
		width: 10%;
		padding-left: 8px;
		padding-right: 8px;
	}

	div.column.is-one-sixth-desktop {
		flex: none;
		width: 16.666666667%;
		padding-left: 8px;
		padding-right: 8px;
	}
}

@media (min-width: $fullhd) {
	div.column.is-one-tenth-fullhd {
		flex: none;
		width: 10%;
		padding-left: 8px;
		padding-right: 8px;
	}

	div.column.is-one-sixth-fullhd {
		flex: none;
		width: 16.666666667%;
		padding-left: 8px;
		padding-right: 8px;
	}
}

.table {
	border-radius: 10px;
	border-collapse: collapse;

	thead.has-grey-head {
		background-color: #F8F8F8;
	}

	td,
	th {
		padding: 8px;
		text-align: center;
		font-size: 0.8em;

		@media (min-width: $tablet) {
			font-size: 0.9em;
		}

		&:first-child {
			border-top-left-radius: 10px;
			border-bottom-left-radius: 10px;
		}

		&:last-child {
			border-top-right-radius: 10px;
			border-bottom-right-radius: 10px;
		}
	}
}

.label.big-title {
	font-size: 1.2em;
	font-weight: 800;
	margin-bottom: 10px;
	margin-left: 10px;
	margin-top: 10px;
}

.round-corner-table {
	border-radius: 20px;
}

.round-corner-table {
	border-radius: 20px;
	box-shadow: 0px 3px 5px #00000029;
}

h4.faq-title {
	font-size: 1em;
	color: $mainColor;
}

h4.faq-question {
	cursor: pointer;
	margin-bottom: 5px;
	font-size: 0.8em;

	@media (min-width: $tablet) {
		font-size: 0.9em;
	}
}

div.faq-answer {
	margin-top: 1rem;
	margin-bottom: 1rem;
	font-size: 0.8em;
}

// TOOLBOX
.toolbox-button {
	&:disabled {
		background-color: #5481db93 !important; //Simulate opacity 50% without touching bulma tooltip
		opacity: 1;
	}

	&.active {
		background-color: $focusGreenMI !important;
		font-weight: 700;
	}
}

.nav-mobile-button {
	margin: 3px;
	width: 35px;
	border: none;

	&:disabled {
		background-color: $noInteractionColor; //Simulate opacity 50% without touching bulma tooltip
		color: rgba(0, 0, 0, 0.445);
		opacity: 1;
	}
}

#video {
	align-items: center;
	max-width: 970px;
}

.classic-search-incentive,
.smart-search-incentive {
	h5 {
		color: $mainColor;
	}
}

.section-title {
	text-align: center;
	font-weight: 800;
	font-size: 1.6rem;
	padding: 0;
	color: black;

	@media (min-width: $tablet) {
		padding: 10px;
	}

	&.white {
		color: white;
	}
}

.highlighting {
	background-color: $yellowMI;
	padding: 10px;
	border-radius: $borderRadius;
	font-weight: bold;
}

.help-center-container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 10px;
	margin-top: 0;

	&.with-video {
		margin-top: 25px;
	}

	h2 {
		margin-left: 10px;
		padding: 0;
	}

	a {
		text-align: center;
	}

	@media (min-width: $tablet) {
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}
}


.video-banner {
	margin-bottom: 30px;
	margin-top: 10px;

	.video-grid {
		display: flex;
		flex-wrap: wrap;
		gap: 20px;

		.video-item {
			// 1 videos by row
			flex: 1 1 100%;

			@media (min-width: 576px) {
				// to replace
				// 2 videos by row
				flex: 1 1 calc(50% - 20px);
			}

			@media (min-width: $tablet) {
				// 3 videos by row
				flex: 1 1 calc(33.33% - 20px);
			}

			@media (min-width: $desktop) {
				// 4 videos by row
				flex: 1 1 calc(25% - 20px);
			}
		}
	}
}